import React from 'react'

const FourOhFour = () => {
  return (<div>
    <h1>Page Not Found</h1>
    <p>/shrug</p>
  </div>)
}

export default FourOhFour
